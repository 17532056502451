import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

// import { Main } from 'styles/privacyPolicy';
import 'styles/privacyPolicy.scss';
const PrivacyPolicy = ({ location }) => (
  <Layout location={location}>
    <Meta
      title="Cookies Policy for ruttl app | ruttl 🍪"
      description="Discover our transparent and user friendly cookie policy, get clear insights on how cookies are used on our ruttl website."
      url="https://ruttl.com/cookie-policy/"
    />
    <main className="privacy-policy-styled-main">
      <div className="container">
        <div className="inner-common-hero">
          <h1 className="privacy-head">Cookies Policy for ruttl app</h1>
          <p>Last updated 23 October, 2020</p>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="privacy-content">
            <h2>Overview:</h2>
            <p>
              ruttl (“we” or “us” or “our”) may use cookies, web beacons,
              tracking pixels, and other tracking technologies when you visit
              our websites (https://web.ruttl.com or https://ruttl.com) as well
              as any other media forms, media channels, mobile websites, or
              mobile application-related or connected thereto (collectively, the
              “Sites”) to help customize the Sites and improve your experience.
            </p>

            <p>
              We reserve the right to make changes to this Cookie Policy at any
              time and for any reason. We will alert you about any changes by
              updating the “Last Updated” date of this Cookie Policy. Any
              changes or modifications will be effective immediately upon
              posting the updated Cookie Policy on the Sites, and you waive the
              right to receive specific notice of each such change or
              modification.
            </p>

            <p>
              You are encouraged to periodically review this Cookie Policy to
              stay informed of updates. You will be deemed to have been made
              aware of to have accepted the changes in any revised Cookie Policy
              that you will be subject to by your continued use of the Sites
              after the date such a revised Cookie Policy is posted.
            </p>

            <h2>USE OF COOKIES</h2>

            <p>
              A “cookie” is a string of information which assigns you a unique
              identifier that we store on your computer. Your browser then
              provides that unique identifier to use each time you submit a
              query to the Sites. We use cookies on the Sites to, among other
              things, keep track of services you have used, record registration
              information, record your user preferences, keep you logged into
              the Sites, facilitate purchase procedures, and track the pages you
              visit. Cookies help us understand how the Sites are being used and
              how to improve your user experience.
            </p>

            <h2>TYPES OF COOKIES</h2>
            <p>
              The following types of cookies may be used when you visit the
              Sites:
            </p>

            <ul>
              <li>
                <strong>Analytics Cookies</strong>
                <p>
                  Analytics cookies monitor how users reached the Sites, and how
                  they interact with and move around once they’re on the Sites.
                  These cookies let us know which features on the Sites work the
                  best and which features on the Sites need to be improved.
                </p>
              </li>

              <li>
                <strong>Our Cookies</strong>
                <p>
                  Our cookies are “first-party cookies” and can be either
                  permanent or temporary. These are necessary cookies, without
                  which the Sites won&apos;t work properly or be able to provide
                  certain features and functionalities. Some of these may be
                  manually disabled in your browser but may affect the
                  functionality of the Sites.
                </p>
              </li>

              <li>
                <strong>Personalization Cookies</strong>
                <p>
                  Personalization cookies are used to recognize repeat visitors
                  to the Sites. We use these cookies to record your browsing
                  history, the pages you have visited, and your settings and
                  preferences each time you visit the Sites.
                </p>
              </li>

              <li>
                <strong>Security Cookies</strong>
                <p>
                  Security cookies help identify and prevent security risks. We
                  use these cookies to authenticate users and protect user data
                  from unauthorized parties.
                </p>
              </li>

              <li>
                <strong>Site Management Cookies</strong>
                <p>
                  Site management cookies are used to maintain your identity or
                  session on the Sites so that you are not logged off
                  unexpectedly, and any information you enter is retained from
                  page to page. These cookies cannot be turned off individually,
                  but you can disable all cookies in your browser.
                </p>
              </li>

              <li>
                <strong>Third-Party Cookies</strong>
                <p>
                  Third-party cookies may be placed on your computer when you
                  visit the Sites by companies that run certain services we
                  offer. These cookies allow the third parties to gather and
                  track certain information about you. These cookies can be
                  manually disabled in your browser.
                </p>
              </li>
            </ul>
            <h2>CONTROL OF COOKIES</h2>
            <p>
              Most browsers are set to accept cookies by default. However, you
              can remove or reject cookies in your browser’s settings. Please be
              aware that this could affect the availability and functionality of
              the Sites.
            </p>

            <p>
              For more information on how to control cookies, check your browser
              or device’s settings for how you can control or reject cookies, or
              visit the following links:
            </p>

            <p>
              <a
                href="https://support.apple.com/kb/ph19214?locale=en_US"
                target="_blank">
                Apple Safari
              </a>
              <br />
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                target="_blank">
                Google Chrome
              </a>
              <br />
              <a
                href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                target="_blank">
                Microsoft Edge
              </a>
              <br />
              <a
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank">
                Microsoft Internet Explorer
              </a>
              <br />
              <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank">
                Mozilla Firefox
              </a>
              <br />
              <a
                href="http://www.opera.com/help/tutorials/security/cookies/"
                target="_blank">
                Opera
              </a>
              <br />
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
                target="_blank">
                Android (Chrome)
              </a>
              <br />
              <a
                href="https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html"
                target="_blank">
                Blackberry
              </a>
              <br />
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1"
                target="_blank">
                iPhone or iPad (Chrome)
              </a>
              <br />
              <a
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
                target="_blank">
                Phone or iPad (Safari)
              </a>
              <br />
            </p>
            <p>
              In addition, you may opt-out of some third-party cookies through
              the
              <a href="http://optout.networkadvertising.org/" target="_blank">
                Network Advertising Initiative’s Opt-Out Tool
              </a>
              .
            </p>

            <h2>OTHER TRACKING TECHNOLOGIES</h2>
            <p>
              In addition to cookies, we may use web beacons, pixel tags, and
              other tracking technologies on the Sites to help customize the
              Sites and improve your experience. A ‘web beacon’ or ‘pixel tag’
              is a tiny object or image embedded in a web page or email. They
              are used to track the number of users who have visited particular
              pages and viewed emails and acquire other statistical data. They
              collect only a limited set of data, such as a cookie number, time
              and date of page or email view, and a description of the page or
              email on which they reside. Web beacons and pixel tags cannot be
              declined. However, you can limit their use by controlling the
              cookies that interact with them.
            </p>

            <h2>PRIVACY POLICY</h2>
            <p>
              For more information about how we use information collected by
              cookies and other tracking technologies, please refer to our&nbsp;
              <Link to="/privacy-policy/">Privacy Policy</Link>
              &nbsp;posted on the Sites. This Cookie Policy is part of and is
              incorporated into our Privacy Policy. By using the Sites, you
              agree to be bound by this Cookie Policy and our Privacy Policy.
            </p>

            <h2>CONTACT US</h2>
            <p>
              If you have questions or comments about this Cookie Policy, please
              email us at support@ruttl.com or write to us at the below address:
            </p>

            <p>
              Brucira Online Solutions Pvt. Ltd.
              <br />
              B504, PNG Building,
              <br />
              Mumbai 400076
              <br />
              Maharashtra, India
            </p>

            <p>+91 7895788535</p>
          </div>
        </div>
      </section>
    </main>
  </Layout>
);

PrivacyPolicy.propTypes = {
  location: PropTypes.object,
};

export default PrivacyPolicy;
